<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

        <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
          <span>
            <span v-if="modeType=='create'">New Approval Chain</span>
            <span v-if="modeType=='edit'">Edit Approval Chain</span>
            <span v-if="modeType=='duplicate'">Duplicate Approval Chain</span>
          </span>
          <span v-if="modeType=='edit'||modeType=='duplicate'"><span class="font-weight-black">{{ itemContent.name }}</span></span>
        </v-card-title>

        <span class="modal-close-btn">
          <v-btn
            icon
            color="grey"
            @click="show=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>

        <v-card-text class="text-center">

          <v-row>

            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    :label="modeType==='duplicate' ? 'New Name' : 'Name'"
                    hide-details
                    v-model="chainName"
                    :rules="nameRules"
                    :counter="50"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    :label="modeType==='duplicate' ? 'New Description' : 'Description'"
                    hide-details
                    v-model="chainDescription"
                    :rules="descRules"
                    :counter="512"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

          <v-row v-if="modeType=='create' || modeType=='duplicate'" class="mb-8 mt-6">
            <v-col>
              <v-switch
                inset
                label="Initially set approval chain as deactivated"
                color="important"
                class="mt-0 pa-0 mr-4"
                hide-details
                false-value="active"
                true-value="inactive"
                value="inactive"
                v-model="chainStatus"
              ></v-switch>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-actions class="d-flex align-center justify-center">
          <v-spacer></v-spacer>
          <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
          <v-btn depressed width="130" color="secondary" @click="saveChain">
            <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
            <span v-if="modeType=='create'">Add</span>
            <span v-if="modeType=='edit'">Update</span>
            <span v-if="modeType=='duplicate'">Duplicate</span>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'ApprovalChainCreateUpdate',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    },
    itemContent: {
      required: false,
      type: Object
    }
  },
  data: () => ({
    valid: true,
    saving: false,
    form: {
      name: '',
      description: '',
      client_id: '',
      status: 'active'
    },
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 50) || 'Name must be less than 50 characters'
    ],
    descRules: [
      v => !!v || 'Description is required',
      v => (v && v.length <= 512) || 'Description must be less than 512 characters'
    ]
  }),
  methods: {
    resetModal () {
      this.$refs.form.reset()
      this.form = {
        status: 'active'
      }
      this.saving = false
    },
    saveChain () {
      this.saving = true
      this.form.client_id = this.$store.state.client.activeClient.clientID
      const axiosAction = (this.modeType === 'create' || this.modeType === 'duplicate') ? 'post' : 'put'
      const axiosUrl = (this.modeType === 'edit') ? '/api/admin/approval-chains/' + this.itemContent.id : '/api/admin/approval-chains'
      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: this.form
      }).then((response) => {
        this.resetModal()
        this.show = false
        this.$emit('reload', true)
      })
    }
  },
  watch: {
    show: function (value) {
      if (value === true && this.modeType === 'edit') {
        this.form.name = this.itemContent.name
        this.form.description = this.itemContent.description
        this.form.client_id = this.itemContent.client_id
        this.form.status = this.itemContent.status
      } else if (value === true && this.modeType === 'create') {
        this.resetModal()
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    chainName: {
      get () {
        return this.itemContent.name
      },
      set (value) {
        this.form.name = value
      }
    },
    chainDescription: {
      get () {
        return this.itemContent.description
      },
      set (value) {
        this.form.description = value
      }
    },
    chainStatus: {
      get () {
        return this.itemContent.status
      },
      set (value) {
        this.form.status = value
      }
    },
    chainClientID: {
      get () {
        return this.itemContent.client_id
      },
      set (value) {
        this.form.client_id = value
      }
    }
  }
})
</script>
