<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Manage Members of <span class="font-weight-black">Name of the Approval Chain</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row class="d-flex align-center">
          <v-col>
            <span class="font-weight-bold text-uppercase text-body-1 secondary--text mr-6">
              In-Sequence
            </span>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-switch
              inset
              label="Sort members from in-sequence list"
              color="secondary"
              class="mt-0 pa-0"
              v-model="sortSequence"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="!sortSequence">
          <v-col>
            <v-select
              v-model="usersSequence"
              :items="users"
              small-chips
              deletable-chips
              outlined
              multiple
              hide-details
              placeholder="In-Sequence Members"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="!sortSequence" class="d-flex mt-6">
          <v-col>
            <span class="font-weight-bold text-uppercase text-body-1 secondary--text mr-6">
              Concurrent
            </span>
          </v-col>
        </v-row>
        <v-row v-if="!sortSequence">
          <v-col>
            <v-select
              v-model="usersConcurrent"
              :items="users"
              small-chips
              deletable-chips
              outlined
              multiple
              hide-details
              placeholder="Concurrent Members"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="sortSequence">
          <v-col>

            <v-card elevation="2">
              <v-simple-table
                height="400"
                :emptyInsertThreshold="400"
              >
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="item in users"
                      :key="item"
                    >
                      <td width="10%">
                        <v-icon color="grey lighten-3">mdi-drag-horizontal-variant</v-icon>
                      </td>
                      <td>{{ item }}</td>
<!--                      <td class="text-right">-->
<!--                        <v-btn text depressed color="important">-->
<!--                          <v-icon size="16">mdi-close</v-icon>-->
<!--                        </v-btn>-->
<!--                      </td>-->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ApprovalChainMembers',
  data: () => ({
    users: [
      'Rico Reis',
      'Vee Caron',
      'Charles Simon',
      'Tony Cappozi',
      'Raph Leroux',
      'Kyler Miles',
      'Stephen Cawley',
      'Arthur da Silva',
      'David Sanchez',
      'Michael Jordan',
      'Pixie Howells',
      'Tayyibah Pratt',
      'Eleri Mccoy',
      'Ishan Hogg',
      'Krishan Vickers',
      'Nayan Wilkinson',
      'Shabaz Bonilla',
      'Franciszek Reed',
      'Finn Rodrigues',
      'Maxine Huff',
      'Tiarna Carter',
      'Chanel Molina',
      'Emerson Kline',
      'Elsie-Mae Shea',
      'Esha Chun',
      'Ishaan Samuels',
      'Avleen Calhoun',
      'Lewie Hale',
      'Cerys Landry',
      'Tayler Horner'
    ],
    usersSequence: [
      'Vee Caron',
      'Charles Simon',
      'Tony Cappozi',
      'Raph Leroux',
      'Kyler Miles',
      'Stephen Cawley',
      'Arthur da Silva',
      'David Sanchez',
      'Michael Jordan',
      'Pixie Howells',
      'Tayyibah Pratt',
      'Eleri Mccoy',
      'Ishan Hogg',
      'Krishan Vickers'
    ],
    usersConcurrent: [
      'Elsie-Mae Shea',
      'Esha Chun',
      'Ishaan Samuels',
      'Avleen Calhoun',
      'Lewie Hale',
      'Cerys Landry'
    ],
    list2: [
    ],
    list3: [
    ],
    sortSequence: false
  }),
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
